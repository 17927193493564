<template>
  <div>
    <v-container
      ><p>
        An ion of rhenium has a mass of 186 amu and contains
        <number-value :value="numElectrons"></number-value>
        electrons. Based on this information, answer the following questions:
      </p></v-container
    >
    <v-simple-table>
      <tbody>
        <tr v-for="(question, index) in questions" :key="question">
          <td>
            {{ question.text }}
          </td>
          <td>
            <v-text-field v-model="inputs[`${questions[index].value}`]" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'CalculateProtonsNeutronsCharge',
  components: {
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        protons: '',
        neutrons: '',
        charge: '',
      },
      questions: {
        protonQuestion: {text: 'a) How many protons does this ion contain?', value: 'protons'},
        neutronQuestion: {text: 'b) How many neutrons does this ion contain?', value: 'neutrons'},
        chargeQuestion: {text: 'c) What is the charge of the ion?', value: 'charge'},
      },
    };
  },
  computed: {
    numElectrons() {
      return this.taskState.getValueBySymbol('numElectrons').content;
    },
  },
};
</script>
